.app-container {
    min-height: 350px;
}

.btn {
    outline: none;
    box-shadow: none;
}

.cursor-pointer {
    cursor: pointer;
}